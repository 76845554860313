import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import Footer from '../CommonComponents/Footer';
import BackHeader from '../CommonComponents/BackHeader';
import {ReactComponent as SongIcon} from '../../assets/icons/song.svg';
import {ReactComponent as StarIcon} from '../../assets/icons/star.svg';
import CustomButton from '../CommonComponents/CustomButton';
import LyricsArea from './LyricsArea';
import CustomInput from '../CommonComponents/CustomInput';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { ArtistType } from '../../types';
import CustomSelect from '../CommonComponents/CustomSelect';
import { getDecoded, loadDraft, removeChordFromLyrics, saveDraft } from '../../utils';
import { useRouteTracker } from '../CommonComponents/RouteTracker';
import { useStore } from '../../store';

type SongInfoType = {
  albumId: number;
  composerId: number;
  lyrics: string;
  singerId: number;
  singerName: string;
  songName: string;
  songPhoto: string;
  tags: string | undefined;
  chordId?: string,
  songId?: string,
  songUid: number,
  chordUid: number
};

export default function LyricsForm() {
  const location = useLocation();
  const songInfo: SongInfoType = location?.state?.songInfo;

  const { previousLocation } = useRouteTracker();

  const {store} = useStore();
  const text = store.text;

  const [lyrics, setLyrics] = useState<string>('');
  const [highLightedLyrics, setHighLightedLyrics] = useState<string>('');

  const [artists, setArtists] = useState<{value: number,label: string}[]>([{value: songInfo.singerId,label: songInfo.singerName}]);

  const [songName, setSongName] = useState<string>(songInfo?.songName || "");
  const [singerId, setSingerId] = useState<number>(songInfo?.singerId);
  const [singerName, setSingerName] = useState<string>(songInfo?.singerName);

  function removeLeadingSpaces(l: string) {
    //remove white space that exist in front of line( Did that logic because of arkar. He think removing whitespaces by himself is delaying their copy-paste-song-adding process)
    const lines = l.split('\n');
    const trimmedLines = lines.map(line => line.replace(/^\s+/, ''));
    return trimmedLines.join('\n');
  }

  const navigate = useNavigate();

  const handleLyricsChange = (l: string) => {
    const removedWhiteSpaces = removeLeadingSpaces(l);
    saveDraft(removedWhiteSpaces, decoded.userId, songInfo.singerId, songInfo.songName, songInfo.chordId);

    setLyrics(removedWhiteSpaces);
    setHighLightedLyrics(removedWhiteSpaces);
  }

  const handleEditChordClick = () => {
    if(lyrics === ""){
      message.info("Please input the lyrics!");
      return;
    }

    const optimizedLyrics = removeEmptyLastLine(lyrics);
    setLyrics(optimizedLyrics);
    localStorage.setItem('chordPro',optimizedLyrics);

    if(isTooLong(optimizedLyrics)){
      return
    }

    songInfo.lyrics = optimizedLyrics;
    songInfo.singerId = singerId;
    songInfo.singerName = singerName;
    songInfo.songName = songName;
    navigate('/dataEntry/editLyrics',{state: {songInfo: songInfo}})
  };

  const isTooLong = (chordPro: string) => {
    const org = chordPro.split('\n');
    const {removedChordLyrics} = removeChordFromLyrics(chordPro);
    const lines = removedChordLyrics;
    const maxWord = 55;
    let tooLong = false;
    let lineNumber = 0;
    let highlightedLyrics = '';

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if(line.length > maxWord){
        lineNumber = i + 1
        highlightedLyrics += `<span style="color: red;">${org[i]}</span>` + '\n';
        tooLong = true;
      } else {
        highlightedLyrics += `${org[i]}\n`;
      }
    }

    if(tooLong){
      message.info(`Too long lines are included`);
      setHighLightedLyrics(highlightedLyrics);
    }
    return tooLong
  }

  function removeEmptyLastLine(lyrics: string) {
    // Split the lyrics into lines
    const lines = lyrics.split('\n');

    while (lines.length > 0 && lines[lines.length - 1].trim() === '') {
      lines.pop(); 
    }

    return lines.join('\n');
}

  const handleArtistSearch = async (v: string) => {
    const result: any = await api.get(endpoints.artist.search + `&keyword=${v}&current=1&limit=5`)
    if(result.code === '200'){
      const formattedArr = result.data?.by?.map((a : ArtistType) => ({value: a?.artist_id, label: a?.name}));
      setArtists(formattedArr)
    }else{
      setArtists([])
    }
  }

  const decoded = getDecoded();
  const uid = decoded?.userId;
  const isUserOwnSong = (uid === songInfo.songUid) ? true : false; // must be song creator , not chord creator


  const loadLyrics = async () => {
    const condition = previousLocation === '/songDetail' || previousLocation === '/dataEntry';
    const ask = condition ? true : false;
    const navigatedFromDataEntry = previousLocation === '/dataEntry';
    const title = text['A draft for this song is found!'];
    const content = text["Do you want to load it?"];
    const okText = text['Yes'];
    const cancelText = text['No'];
    const c = await loadDraft(title, content, okText, cancelText, decoded.userId, songInfo.songName, songInfo.singerId, songInfo.chordId, ask, navigatedFromDataEntry);
    setLyrics(c);
    setHighLightedLyrics(c);
  }

  const mounted = useRef(false);

  useEffect(()=> {
    if(!mounted.current) return;
    loadLyrics();
  }, [previousLocation])

  useEffect(()=> {
    mounted.current = true;
  },[])
  
  return (
    <div>
      <BackHeader/>
      <NextHeader songInfo={songInfo} singerName={songInfo?.singerName} songName={songInfo?.songName} handleEditChordClick={handleEditChordClick}/>
      {
        isUserOwnSong && 
        <div style={{display: 'flex',justifyContent: 'center',flexDirection: "column",gap: "20px",alignItems: 'center',paddingBottom: "10px"}}>
          <CustomInput value={songName} onChange={(e) => setSongName(e.target.value)} placeholder='Song Name'  prefix={<SongIcon />} customStyle={{width: "350px"}}/>
          <CustomSelect
              value={singerId}
              size='large'
              showSearch
              filterOption={false}
              suffixIcon={<></>} 
              prefixIcon={<StarIcon />}
              placeholder="Singer"
              onSearch={handleArtistSearch}
              options={artists}
              style={{width: "350px"}}
              onChange={(v)=> {
                const s = artists.filter((a : any) => a.value === v)[0];
                setSingerId(s.value);
                setSingerName(s.label);
              }}
            />
        </div>
      }
      <LyricsArea isUserOwnSong={isUserOwnSong} lyrics={lyrics} highLightedLyrics={highLightedLyrics} handleLyricsChange={handleLyricsChange}/>
      {/* <Footer/> */}
    </div>
  )
}


type NextHeaderProps =  {
    songName: string,
    singerName: string,
    handleEditChordClick: Function,
    songInfo: SongInfoType
}

const NextHeader = ({songName = "",singerName = "", handleEditChordClick, songInfo} : NextHeaderProps ) => {
  const decoded = getDecoded();
  const uid = decoded?.userId;
  const isUserOwnSong = (uid === songInfo.songUid) ? true : false; // must be song creator , not chord creator
  
  return (
      <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",margin: "0 17px"}}>
        {!isUserOwnSong && 
          <div style={{display: "flex",flexDirection: "column",flexBasis: "50%"}}>
            <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: "white",marginBottom: "5px",fontFamily: 'Arial, sans-serif'}}>{songName}</h1>
            <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: "gray",fontFamily: 'Arial, sans-serif'}}>{singerName ||  ''}</h1>
          </div>
        }
        <div style={{display: "flex",alignItems: "end",justifyContent: "end",width: "100%"}}>
          <CustomButton onClick={()=> handleEditChordClick()} style={{padding: '10px 20px', width: "88px"}}  active>Next</CustomButton>
        </div>
      </div>
  )
}



