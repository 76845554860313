export const endpoints  = {
    user: {
      login: '/user/loginCallback',
      link: 'user/linkCallback',
      getProfile: 'user/getProfile',
      updateProfile: 'user/updateProfile'
    },
    artist: {
      addArtist: 'artist/setArtist',
      getArtists: 'artist/getAllArtists',
      getArtistById: 'artist/getArtistById',
      search: 'search/by?type=artist'
    },
    common: {
      search: 'search/by'
    },
    playlist: {
      getPlaylist: 'playlist/getPlayList',
      getPlayListDetail: 'playlist/getPlayListDetail',
      addPlayList: 'playlist/addPlayList',
      addSongPlayList: 'playlist/addSongPlayList',
      updatePlayList: 'playlist/updatePlayList',
      deletePlayList: 'playlist/deletePlayList',
    },
    collection: {
      getCollection: 'collection/getCollection',
      getSongsByCollection: 'collection/getSongsByCollection'
    },
    song: {
      addSong: 'song/setSong',
      setFav: 'song/setFavorite',
      getFav: 'song/getFavorite',
      getChordsBy: 'song/getChordsBy',
      getVersionsBy: 'song/getVersionsBy',
      getDetail: 'song/detail',
      popularSong: 'song/popularSongs',
      latestSong: 'song/latestSongs',
      songEdit: 'song/updateChordsBy'
    },
    alblum: {
      addAlbum: 'album/setAlbum',
      getAlbum: 'album/getAllAlbums',
      getAlbumById: 'album/getAlbumById',
      search: 'search/by?type=album'
    },
    composer: {
      search: 'search/by?type=composer'
    },
    chord: {
      getChords: 'chord/getChords'
    },
    storage: {
      upload: 'storage/upload'
    },
    codePush: {
      checkUpdate: 'https://lingash.me/api/check-update'
    }
  } ;