// import React, { useEffect, useState } from 'react';
// import { Drawer } from 'vaul';

// import config from '../../config';
// import CustomWheelPicker from '../CommonComponents/CustomWheelPicker';
// import CustomButton from "../CommonComponents/CustomButton";
// import CustomDrawer from '../CommonComponents/CustomDrawer';
// import api from '../../api/apiClient';
// import { endpoints } from '../../api/endpoints';
// import { ChordDataType } from '../../types';
// import { message } from 'antd';

// interface ChordAddDrawerProps {
//   isOpen: boolean;
//   onClose: () => void;
//   handleAddChord: (params: { chord: string; key: string }) => void;
// }
// const ChordAddDrawer: React.FC<ChordAddDrawerProps> = ({ isOpen, onClose, handleAddChord }) => {
//   const [chords, setChords] = useState<{ value: string; id: string; }[]>([{id: "0",value: "jelwjlf"}]);
  
//   const [keys, setKeys] = useState<{ value: string; id: string; }[]>([{id: "0",value: 'elwflwel'}]);

//   const [selectedKey, setSelectedKey] = useState<{id: string,value: string}>();
//   const [selectedChord, setSelectedChord] = useState<{id: string,value: string}>(chords[0]);

//   const handleChordChange = (value: {id: string,value: string}) => {
//     setSelectedChord(value);
//   }

//   const handleKeyChange = (value: {id: string,value: string}) => {
//     setSelectedKey(value);
//   }

//   const getKey = async () => {
//     const result: any = await api.get(endpoints.chord.getKey);
//     if(result.code === 200) {
//       let idCounter = 0;
//       const formattedArr: { value: string; id: string }[] = result.data?.map(
//         (k: { key: string }) => ({
//           value: k?.key,
//           id: (idCounter++).toString(), // Convert the numeric idCounter to a string
//         })
//       );
//       setSelectedKey(formattedArr[Math.ceil(formattedArr.length / 2)]);
//       setKeys(formattedArr)
//     }else{
//       setKeys([]);
//     }
//   }

//   const getChord = async () => {
//     const result : any = await api.get(endpoints.chord.getChord + `?key=${selectedKey?.value}&current=1&limit=1000`);
//     if(result.code === 200) {
//       const data: ChordDataType = result.data;
//       let idCounter = 0;
//       const formattedArr: { value: string; id: string }[] = data.chords?.map(
//         (k) => ({
//           value: k?.chord,
//           id: (idCounter++).toString(), // Convert the numeric idCounter to a string
//         })
//       );
//       setSelectedChord(formattedArr[Math.ceil(formattedArr.length / 2)]);
//       setChords(formattedArr)
//     }else{
//       setChords([]);
//     }
//   }

//   useEffect(()=> {
//     // getKey();
//   },[])

//   useEffect(()=> {
//     // getChord();
//   },[selectedKey])

//   console.log("keys",keys)
//   console.log("chord",chords)

//   return (
//     <div>
//         <CustomDrawer isOpen={isOpen} onClose={onClose}>
//             <div style={{display: 'flex',justifyContent: "space-evenly"}}>
//               <CustomWheelPicker uniqueId='wheel-keys' data={keys} handleDataChange={handleKeyChange}/>
//               <CustomWheelPicker uniqueId='wheel-chords' data={chords} handleDataChange={handleChordChange}/>
//             </div>
//             <div style={{display: "flex",justifyContent: 'center'}}>
//               <CustomButton onClick={() => {
//                 if(!selectedKey?.value) return message.info('Select chord first!')
//                 handleAddChord({chord: selectedChord.value,key: selectedKey?.value})
//               }} active style={{textAlign: 'center',paddingTop: "15px",paddingBottom: "15px"}}>
//                 Add Chord
//               </CustomButton>
//             </div>
//         </CustomDrawer>
//     </div>
//   );
// };

// export default ChordAddDrawer;



import React from 'react'

export default function Test() {
  return (
    <div>Test</div>
  )
}
