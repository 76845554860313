// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode} from 'swiper/modules';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { endpoints } from '../../api/endpoints';
import { useEffect, useState } from 'react';
import { SongDataType } from '../../types';
import api from '../../api/apiClient';
import CustomImage from '../CommonComponents/CustomImage';
import {ReactComponent as ViewIcon} from '../../assets/icons/view-arrow.svg'
import { useStore } from '../../store';


type SongsSliderProps = {
    loading: boolean
}

const SongsSlider : React.FC<SongsSliderProps> = ({loading}) => {
  const {store} = useStore();
  const text = store.text;
  const [lastestSongs,setLatestSongs] = useState<SongDataType>();
  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate('/home/newSongs')
  }

  const getData = async () => {
    const result: any = await api.get(endpoints.song.latestSong + `?current=${1}&limit=10`);

    if(result.code !== '200') return;
    const data: SongDataType = result.data;
    setLatestSongs(data);
  }

  useEffect(()=> {
    getData();
  },[])

  return (
    <div style={{ padding: '30px 0 0px 0' }}>
          <div style={{padding: "14px 14px 16px 14px", display: 'flex',flexDirection: "row",justifyContent:"space-between",alignItems: "center"}}>
            <h2 className="" style={{fontSize: "20px",color: "white",margin: 0}}>{text['Newly Added Songs']}</h2>
            {/* <ButtonBase style={{
              padding: '12px 30px',
              borderRadius: '20px',
              fontSize: "10px",
              backgroundColor: '#ffffff',
              color: '#3D3D3D',
              border: 'none',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
              marginBottom: '20px',
            }} onClick={handleViewClick}>
              View
            </ButtonBase> */}
            <ViewIcon onClick={handleViewClick}/>
          </div>
          <div style={{padding: "0 14px"}}>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={10}
              freeMode={true}
              modules={[FreeMode]}
              className="mySwiper"
            >
              {lastestSongs?.by?.map((song, index) => (
                <SwiperSlide key={index} style={{width: "147px"}}>
                  <div style={{width: "147px"}}>
                    <CustomImage
                      onClick={(e)=>{
                        navigate('/songDetail',{state: song})
                      }}
                      src={song.image_url}
                      alt={`Song ${song.name}`}
                      width="147px" height="147px" borderRadius='10px'
                    />
                    <div style={{display: "flex",justifyContent: "center",alignItems: 'center',width: "100%"}}>
                      <h2 className="custom-font" style={{color: "white",textAlign: "center",fontSize: "15px",margin: "2px 0px 0px 0px",width: "130px", lineHeight: "25px"}}>{song.name}</h2>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
  
        </div>
  )
}

export default SongsSlider;