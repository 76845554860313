import { useNavigate } from "react-router-dom";
import {ReactComponent as HomeIcon} from '../../assets/icons/home.svg';
import {ReactComponent as SongIcon} from '../../assets/icons/song.svg';
import {ReactComponent as CircleIcon} from '../../assets/icons/add-circle.svg';
import {ReactComponent as Bookmark} from '../../assets/icons/bookmark.svg';

import { ButtonBase } from "@mui/material";
import { useStore } from "../../store";
import config from "../../config";

export default function Footer() {
  const navigate = useNavigate();
  const {store,updateStoreFunc} = useStore(); 
  const handleNavigationClick = (link: string) =>{
    updateStoreFunc("currentPage",link);
    navigate(`${link}`);
  }

  const pageSelectedColor = config.color.pageSelectedColor;
  const pageNameColor = config.color.pageNameColor;
  const footerBgColor = config.color.footerBgColor;

  const text = store.text;
  
  return (

      <div style={{maxWidth: config.screenWidth,height: "60px",marginLeft: "auto",marginRight:"auto",display: "flex",flexDirection: "row",justifyContent: "space-evenly",alignItems: "center",padding: "",position: "fixed",bottom: 0,right: 0,left: 0,zIndex: 1000,backgroundColor: footerBgColor}} >
        <ButtonBase   style={{width: '55px',height: "50px",borderRadius: "25px"}}  sx={{'&:hover': {opacity: "0.8"}}} onClick={()=>handleNavigationClick("/home")}>
            <div style={{display: "flex",flexDirection: "column",justifyContent: 'center',alignItems: "center"}}>
              <HomeIcon fill={(store.currentPage == "/home") ? pageSelectedColor : "none"}/>
              <h5 style={{margin: "5px 0 0 0",fontSize: "10px",color: pageNameColor}}>{text.Home}</h5>
            </div>
        </ButtonBase>

        <ButtonBase   style={{width: '55px',height: "50px",borderRadius: "25px"}}  sx={{'&:hover': {opacity: "0.8"}}} onClick={()=>handleNavigationClick("/dataEntry")}>
          <div style={{display: "flex",flexDirection: "column",justifyContent: 'center',alignItems: "center"}}>
            <CircleIcon fill={(store.currentPage == "/dataEntry") ? pageSelectedColor : "none"}/>
            <h5 style={{margin: "5px 0 0 0",fontSize: "10px",color: pageNameColor}}>{text['Data Entry']}</h5>
          </div>
        </ButtonBase>

        <ButtonBase   style={{width: '55px',height: "50px",borderRadius: "25px"}}  sx={{'&:hover': {opacity: "0.8"}}} onClick={()=>handleNavigationClick("/library")}>
          <div style={{display: "flex",flexDirection: "column",justifyContent: 'center',alignItems: "center"}}>
            <Bookmark fill={(store.currentPage == "/library") ? pageSelectedColor : "none"}/>
            <h5 style={{margin: "5px 0 0 0",fontSize: "10px",color: pageNameColor}}>{text.Library}</h5>
          </div>
        </ButtonBase>

        <ButtonBase   style={{width: '55px',height: "50px",borderRadius: "25px"}}  sx={{'&:hover': {opacity: "0.8"}}} onClick={()=>handleNavigationClick("/profile")}>
          <div style={{display: "flex",flexDirection: "column",justifyContent: 'center',alignItems: "center"}}>
            <SongIcon width={25} height={25} fill={(store.currentPage == "/profile") ? pageSelectedColor : "none"}/>
            <h5 style={{margin: "5px 0 0 0",fontSize: "10px",color: pageNameColor}}>{text.Profile}</h5>
          </div>
        </ButtonBase>

      </div>

  )
}
