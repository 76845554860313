const config = {
    // backendApi: "http://localhost:8000/api",
    backendApi: "https://dev.ywar.com/guitar",
    socketApi: "",
    screenWidth: "414px",
    termsAndConditionsLink: "https://mmguitar.ywar.com/terms.html",
    tiktok: {
        clientKey: "sbawp2gihxd6l6y9tc",
        redirectUri: "guitar://auth"
    },
    google: {
        clientKey: "971825694230-cphs2re1de3jubom7as5m4b8ii9nmq72.apps.googleusercontent.com",
        adMobAppId: "ca-app-pub-6182318181391980~4341796268",
        adMobAdId: "ca-app-pub-3940256099942544/1033173712"
    },
    facebook: {
        appId: "960012162480817" 
    },
    color: {
        primaryColor: "#2D333B",
        secondaryColor: "#8054DB",
        sideBarColor: "#22272E",
        mainColor: "#1C2128",
        footerBgColor: "#1F1F1F",
        pageSelectedColor: "grey",
        pageNameColor: "#474747"
    }
}
export default config ;