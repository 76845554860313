import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import Footer from "../CommonComponents/Footer";
import Carousel from './Carousel';
import SongsSlider from './SongsSlider';
import ArtistsSlider from './ArtistsSlider';
import { useStore } from '../../store';


function Home() {
  const {store} = useStore();
  const [loading, setLoading] = useState(true);

  const handleLoading = (value: boolean) => {
    setLoading(value);
  }

  return (
    <div >
      
      <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight}} >
          <>
            <Carousel loading={loading} handleLoading={handleLoading}/>
            <SongsSlider loading={loading}/>
            <ArtistsSlider loading={loading}/>
          </>
      </div>
      <Footer/>
    </div>
  );
}



export default Home;