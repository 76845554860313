import { AdLoadInfo, AdMob, AdOptions } from '@capacitor-community/admob';
import config from "../config";

export class AdMobService {
    private static instance: AdMobService;

    constructor() {
        if (AdMobService.instance) {
            return AdMobService.instance;
        }
        this.init();
        AdMobService.instance = this;
    }

    private init() {
        AdMob.initialize();
    }

    async loadInterstitial(): Promise<AdLoadInfo | null> {
        const options: AdOptions = {
          adId: config.google.adMobAdId,
        };
      
        try {
          const value: AdLoadInfo = await AdMob.prepareInterstitial(options);
          console.log("prepare ad value: ",value);
          return value;
        } catch (error) {
          console.error('Error loading interstitial ad:', error);
          return null
        }
    }

    async showInterstitial(): Promise<void> {
        try {
          await AdMob.showInterstitial();
          console.log("showed ad");
        } catch (error) {
          console.error('Error showing interstitial ad:', error);
        }
    }
}