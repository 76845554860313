import React, { useEffect, useState } from 'react'
import Footer from '../CommonComponents/Footer'
import { ButtonBase } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import mmFlag from '../../assets/images/my.png'
import engFlag from '../../assets/images/eng.png'
import {ReactComponent as FbIcon} from '../../assets/icons/facebook-app-symbol.svg'
import {ReactComponent as TikTokLogo} from '../../assets/icons/tik-tok.svg'
import {ReactComponent as Google} from '../../assets/icons/google.svg'
import {ReactComponent as CheckIcon} from '../../assets/icons/check-circle2.svg'
import {ReactComponent as UncheckIcon} from '../../assets/icons/uncheck-circle2.svg'
import CustomButton from '../CommonComponents/CustomButton'
import { useStore } from '../../store'
import { ProfileType } from '../../types'
import api from '../../api/apiClient'
import { endpoints } from '../../api/endpoints'
import CustomImage from '../CommonComponents/CustomImage'
import { GoogleService } from '../../socialLogin/goolge'
import { FacebookService } from '../../socialLogin/facebook'
import { generateTikTokCodeVerifier } from '../../utils'
import config from '../../config'
import { message } from 'antd'
import TikTok from '../../plugins/tiktokPlugin'

export default function SongRequest() {
  const {store, updateStoreFunc} = useStore();
  const text = store.text;
  const navigate = useNavigate();
  const [profile,setProfile] = useState<ProfileType>();

  const GL = new GoogleService();
  const FB = new FacebookService();

  const [loading, setLoading] = useState({
    value: false,
    type: ''
  });

  const handleLogout = () => {
    localStorage.clear();
    GL.logout();
    updateStoreFunc('currentPage', '/home'); // update home page . only then when user back from login, home page is active
    navigate('/login');
  }

  const getData = async () => {
    const result : any= await api.get(endpoints.user.getProfile);
    if(result.code !== '200') return;
    setProfile(result.data as ProfileType);
  }

  useEffect(()=> {
    getData();
  },[]);

  const handleClickEditProfile = () => {
    navigate('/profile/editProfile', {state: {
      profile: profile
    }});
  };


  const linkClick = async (type: string) => {
    setLoading({
        value: true,
        type
    });
    let params : any ={
        type
    };
    if(type === 'facebook'){
      params.code = await FB.login();

    }else if (type === 'tiktok'){
      const codeVerifier = generateTikTokCodeVerifier();
      const result = await TikTok.login({ clientKey: config.tiktok.clientKey, codeVerifier, redirectUri: config.tiktok.redirectUri });
      console.log('Response from tiktok:', result);
      params.code = result.authCode + " " + codeVerifier;

    }else if (type === 'google'){
      params.code = await GL.login();
    }else{
      return;
    }

    const result : any = await api.post(endpoints.user.link, params);
    setLoading({
        value: false,
        type
    });

    if(result.code === '200') {
        message.success("Link Success!");
        getData();
    }else{
        GL.logout();
        message.error(result.message);
    }
  }

  return (
    <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight, position: 'relative'}}>
      <div style={{position: 'absolute',width: "100%"}}>
          {
            profile?.coverPhoto ?
            <img style={{height: "157px", width: "100%",objectFit: 'cover'}} src={profile?.coverPhoto}/>
            :
            <div  style={{height: "157px", width: "100%",backgroundColor: "black"}}></div>
          }
    </div>
      <div style={{ top: "40px", padding: "20px 0", position: 'absolute', width: "100%"}}>
        <div style={{display: "flex", top: "40px",justifyContent: "space-between",alignItems: "center",padding: "0 17px"}}>
          <h1 style={{margin: 0, color: 'white', fontSize: "30px"}}>{text.Profile}</h1>
          <div style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
            <ButtonBase onClick={()=> updateStoreFunc("lang","my")} style={{padding: "0 20px 0 0"}}><img style={{border: store.lang === 'my' ? '1px solid white' : ""}} src={mmFlag}/></ButtonBase>
            <ButtonBase onClick={()=> updateStoreFunc("lang","eng")} style={{padding: "0"}}><img style={{border: store.lang === 'eng' ? '1px solid white' : ""}} src={engFlag}/></ButtonBase>
          </div>
        </div>
      </div>
      <div style={{position: "absolute",width: "100%",zIndex: 1000,top: "130px"}}>
        <div style={{padding: "0 17px", display: "flex",justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{width: "100px",height: "100px",borderRadius: "50px",border: "1px solid white"}}>
              <CustomImage src={profile?.avatar || ""} width={'100px'} height={'100px'} borderRadius='50px'/>
            </div>
            <h1 style={{color: "white",fontSize: "20px"}}>{profile?.name}</h1>
          </div>
          <div style={{marginTop: "30px"}}>
            <CustomButton onClick={() => handleClickEditProfile()} style={{backgroundColor: "transparent",border: "1px solid white",padding: "10px 20px"}} active={false}>{text['Edit Profile']}</CustomButton>
          </div>
        </div>
      </div>
      <div style={{padding: "0 17px"}}>
          <div style={{height: "260px"}}>
            
          </div>
          <h2 style={{fontSize: "15px",color: "#BFBFBF"}}>{profile?.bio}</h2>
          <div style={{display: "flex"}}>
            <h2 style={{color: 'white',fontSize: "15px",marginRight: "10px"}}>{profile?.songCount || 0} <span style={{color: '#747474'}}>songs</span></h2>
          </div>
          <h1 style={{color: "#747474",fontSize: "16px",marginTop: "24px"}}>Linked account</h1>
          <div style={{display: 'flex',flexDirection: "column"}}>
              <ButtonBase disabled={loading.value} onClick={() => linkClick('facebook')} style={{marginTop: "12px",display: "flex",justifyContent: "space-between",alignItems: "center",padding: "12px 18px",border: "1px solid #333333"}}>
                <div style={{display: "flex",alignItems: "center"}}>
                  <FbIcon/>
                  <h2 style={{marginLeft: "24px",color: '#EEEEEE',fontSize: "16px"}}>Facebook</h2>
                </div>
                {
                  profile && profile?.linked_account.findIndex( l => l.type === 'facebook') !== -1 ?
                  <CheckIcon/>
                  :
                  <UncheckIcon/>
                }
              </ButtonBase>
              <ButtonBase disabled={loading.value} onClick={() => linkClick('tiktok')} style={{marginTop: "12px",display: "flex",justifyContent: "space-between",alignItems: "center",padding: "12px 18px",border: "1px solid #333333"}}>
                <div style={{display: "flex",alignItems: "center"}}>
                  <TikTokLogo/>
                  <h2 style={{marginLeft: "24px",color: '#EEEEEE',fontSize: "16px"}}>Tik Tok</h2>
                </div>
                {
                  profile && profile?.linked_account.findIndex( l => l.type === 'tiktok') !== -1 ?
                  <CheckIcon/>
                  :
                  <UncheckIcon/>
                }
              </ButtonBase>
              <ButtonBase disabled={loading.value} onClick={() => linkClick('google')} style={{marginTop: "12px",display: "flex",justifyContent: "space-between",alignItems: "center",padding: "12px 18px",border: "1px solid #333333"}}>
                <div style={{display: "flex",alignItems: "center"}}>
                  <Google/>
                  <h2 style={{marginLeft: "24px",color: '#EEEEEE',fontSize: "16px"}}>Google</h2>
                </div>
                {
                  profile && profile?.linked_account.findIndex( l => l.type === 'google') !== -1 ?
                  <CheckIcon/>
                  :
                  <UncheckIcon/>
                }
              </ButtonBase>
          </div>
          <div style={{display: 'flex',justifyContent: 'center', marginTop: "20px"}}>
            <CustomButton onClick={handleLogout} active={false} style={{backgroundColor: "#EF3333"}}>{text['Log Out']}</CustomButton>
          </div>
          <Footer/>
      </div>
    </div>
  )
}
