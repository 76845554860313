/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

import logo from "../../assets/images/logo-horizontal.png";
import {ReactComponent as SearchIcon} from "../../assets/icons/searchIcon.svg";
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { CollectionDataType, ProfileType, SongDataType, SongType } from '../../types';
import config from '../../config';
import CustomImage from '../CommonComponents/CustomImage';
import Skeleton from 'react-loading-skeleton';
import { useStore } from '../../store';
import {ReactComponent as ViewIcon} from '../../assets/icons/view-arrow.svg'

type CarouselProps = {
    loading: boolean;
    handleLoading: (value: boolean) => void
}

const Carousel: React.FC<CarouselProps> = ({loading, handleLoading})  => {
  const [collections, setCollections] = useState<CollectionDataType>();
  const [profile,setProfile] = useState<ProfileType>();

  const {store, updateStoreFunc} = useStore();
  const navigate = useNavigate();

  const handleViewClick = (c: CollectionDataType[0]) => {
    navigate('/home/collectionList',{state: {collectionId: c.id, collectionName: c.name}})
  }

  const handleSearchClick = () => {
    navigate('/home/search');
  }

  const getData = async () => {
    const result: any = await api.get(endpoints.collection.getCollection);
    handleLoading(false);
    if(result.code !== '200') return;
    const data: CollectionDataType = result.data;
    setCollections(data);
  }

  const getProfile = async () => {
    const result : any= await api.get(endpoints.user.getProfile);
    if(result.code !== '200') return;
    setProfile(result.data as ProfileType);
  }

  const handleProfileClick = (c: CollectionDataType[0],e : any) => {
    e.stopPropagation();
    const obj = {userId: c.song.user.user_id};
    navigate('/userProfile',{state: obj});
  }

  useEffect(()=> {
    getData();
    getProfile();
  },[])

  return (
    <div style={{position: "relative"}}>
        <>
          <div>
            <div style={{width: "100%",backgroundColor: 'transparent'}}>
              <div style={{ padding: "10px",display: 'flex', justifyContent: 'space-between',alignItems: "center"}}>
                <img src={logo} width={"115px"} height={"60px"}/>
                <div style={{display: 'flex',alignItems: 'center'}}>
                  <ButtonBase onClick={handleSearchClick}>
                    <SearchIcon style={{marginRight: "15px"}}/>
                  </ButtonBase>
                  <ButtonBase onClick={()=> {
                    navigate('/profile');
                    updateStoreFunc('currentPage','/profile')
                  }}
                  style={{paddingRight: "10px"}}
                  >
                    <CustomImage src={profile?.avatar || ""} alt={profile?.name} width={'32px'} height={'32px'} borderRadius='20px'/>
                  </ButtonBase>
                </div>
              </div>
            </div>
          </div>
          {!loading && <CustomImage  width='100%' height='418px' borderRadius="0 0 0px 0px" style={{position: "absolute"}}
              src={collections ? collections[0].cover_image: ""}
              alt={collections ? collections[0].name : ""}
          />}
          <Swiper
              className="mySwiper"
              centeredSlides={true}
              autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
          >
            {loading && 
              <Skeleton width='100%' height='418px' borderRadius="0 0 0px 0px"/>
            }
            {!loading && collections?.map(c => (
            <SwiperSlide key={c.id}>
              <div key={c.id} onClick={()=>handleViewClick(c)} style={{zIndex: 1000}}>
                <div style={{ position: 'relative',height: '418px' }}>
                  <div style={{position: 'absolute',top: "15px",width: "100%",display: 'flex',justifyContent: 'center'}}>
                      <h1 css={css`font-family: khyay !important ;`} style={{fontSize: "40px", fontFamily: "khyay"}}>{c.name}</h1>
                  </div>
                  <div style={{position: 'absolute',top: "120px",left: "30px",width: "100%"}}>
                    <div style={{position: 'relative'}}>
                      <img src={c.image}/>
                      <div style={{position: "absolute",top: "0px",display: 'flex',flexDirection: "column", width: "230px", marginLeft: "10px"}}>
                        <h1 css={css`font-family: sans-pro !important ;`} style={{fontSize: "25px", fontWeight: "normal", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "center", marginBottom: "10px", marginLeft: "5px",marginRight: "5px"}}>{c.song.name}</h1>
                        <div style={{marginLeft: "auto", marginRight: "auto", }}>
                          <div>
                            <img src={c.song.image_url} style={{height: "147px", width: "147px", borderRadius: "10px"}}/>
                            <div style={{display: 'flex',alignItems: 'center',zIndex: 4000}}>
                                <img src={c.song.user.avatar} style={{borderRadius: "20px",marginRight: "5px"}} width={"20px"} height={"20px"} onClick={(e) => handleProfileClick(c,e)}/>
                                <h5 className="custom-font" style={{color: "#565656", fontSize: "12px", fontWeight: "normal",lineHeight: "15px",margin: "0"}} onClick={(e) => handleProfileClick(c,e)}>{c.song.user.name}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
    </div>
  )
}

export default Carousel;