import React, { useEffect, useState } from 'react'
import BackHeader from './BackHeader'
import { useStore } from '../../store';
import { DataType, SongDataType, SongType } from '../../types';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import CustomList from './CustomList';
import { useLocation } from 'react-router-dom';

export default function SongVersionsList() {
    const {store} = useStore();
    const text = store.text;
    const [data, setData] = useState<DataType>(null);
    const [loading,setLoading] = useState(false);
    const [hasMore,setHasMore] = useState(true);
    const [page,setPage] = useState(1);

    const location = useLocation();
    const songId = location.state.songId;
  
    const handleFavoriteToggle = async (songId: number,chordId: number, favorite: string) => {
      const fav = favorite === "1" ? "0" : "1";
      api.post(endpoints.song.setFav,{songId: songId,chordId: chordId, favorite: fav})
      setData((pre: any) => {
        let obj: any = {...pre};
        let arr: any = [...obj.by];
        obj.by = arr?.map((song: SongType) =>
          song.song_id === songId ? { ...song, favorite: fav } : song
        )
        return obj
      }
      );
    };
  
    const fetchData = async () => {
      if(page === 1) setLoading(true);
  
      const result: any = await api.get(endpoints.song.getVersionsBy + `?songId=${songId}current=${page}&limit=10`);
      if(page === 1) setLoading(false);
      if(result.code === '200'){
        const d : SongDataType = result.data;
        setData((pre) => {
          let obj: any = {...pre};
          let arr : any;
          if(page > 1){
            if(pre && d) arr = [...pre?.by,...d?.by]
            obj.by = arr;
            return obj
          }else{
            return d
          }
        });
      }else{
        if(page > 1){
          setHasMore(false);
        }else{
          setData(null);
        }
      }
    }
    
    useEffect(() => {
      fetchData();
    },[page])
  
    useEffect(() => {
      if(data?.by?.length === data?.pagination?.total) setHasMore(false);
    },[data])
  
    const handleFetchMore = () => {
      setPage(pre => pre + 1)
    }
  return (
    <div>
      <div>
        <BackHeader/>
        <h1 style={{position: 'fixed', top: 0, left: "50%",transform: 'translate(-50%, 0)',color: "white", fontSize: "22px"}}>
          {text['Versions']}
        </h1>
      </div>
    <div style={{padding: "0 15px"}}>
        <CustomList
          data={data}
          type={'Song'}
          onFavoriteToggle={handleFavoriteToggle}
          showFavorite
          loading={loading}
          handleFetchMore={handleFetchMore}
          hasMore={hasMore}
          scrollHeight={store.screenHeight}
        />
    </div>
    </div>
  )
}
