/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { MouseEventHandler, UIEventHandler, useEffect, useRef, useState } from 'react'
import { ButtonBase } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode} from 'swiper/modules';
import { Transposer } from 'chord-transposer';
import Skeleton from 'react-loading-skeleton';
import { message } from 'antd';

import {ReactComponent as Tag} from '../../assets/icons/tag2.svg';
import {ReactComponent as Setting} from '../../assets/icons/setting2.svg';
import {ReactComponent as Copy} from '../../assets/icons/copy.svg';
import {ReactComponent as Edit} from '../../assets/icons/edit.svg';
import {ReactComponent as LightMode} from '../../assets/icons/light-mode.svg';
import {ReactComponent as DarkMode} from '../../assets/icons/dark-mode.svg';

import { customSplit, getDecoded, removeChordFromLyrics } from '../../utils';

import { AdMobService } from "../../socialLogin/googleAdmob";
import BackHeader from './BackHeader';
import { useStore } from '../../store';
import CustomButton from './CustomButton';
import CustomDrawer from './CustomDrawer';
import CustomWheelPicker from './CustomWheelPicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { SongDetailsType, SongType } from '../../types';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import {ReactComponent as PlaylistIcon} from '../../assets/icons/plus-circle.svg'
import { useRouteTracker } from "./RouteTracker";

const SongDetail: React.FC = () => {
   let [lyrics,setLyrics] = useState<string[]>([]);
   const [loading,setLoading] = useState(true);
   let [chordPositions, setChordPositions]  = useState<Record<string, string>>({});
   const [songDetailData,setSongDetailData] = useState<SongDetailsType>();
   const [fav,setFav] = useState<string>('0');
   const [scroll,setScroll] = useState<string>('0x');
   const [isSettingDrawerOpen,setIsSettingDrawerOpen] = useState(false);
   const [lyricsFontsize,setLyricsFontsize] = useState('16px');
   const [chordFontsize,setChordFontsize] = useState('12px');
   const [orgChordPositions,setOrgChordPositions] = useState<Record<string, string>>({});
   const [lightThemeOn, setLightThemeOn] = useState(false);
  
   const AS = new AdMobService();

   const formattedLyrics = useRef('');

   const location = useLocation();
   const navigate = useNavigate();
   const song : SongType = location.state;

  const getDetail = async (chordId = song.chord_id) => {
    const result: any = await api.get(endpoints.song.getDetail+ `?songId=${song.song_id}&chordId=${chordId}`);
    setLoading(false);
    if(result.code !== "200") return message.error(result.message);
    const songDetail: SongDetailsType = result.data;
    setSongDetailData(songDetail);
    const l = songDetail?.details?.chord_pro;
    formattedLyrics.current = l;
    const {positions,removedChordLyrics} = removeChordFromLyrics(l);
    setLyrics(removedChordLyrics);
    setFav(songDetail.details.favorite)
    setChordPositions(positions);
    setOrgChordPositions(positions);
  }

  const isUserScrolling = useRef(false);

  const handleTouchStart = () => isUserScrolling.current = true;

  const handleTouchEnd = () => isUserScrolling.current = false;
  
  useEffect(() => {
    const lyricsContainer = document.getElementById('lyrics-detail');
      let scrollIntervalId: NodeJS.Timeout | null = null;

      clearInterval(scrollIntervalId!)

      const scrollSpeeds: Record<string,{interval: number,step: number}> = {
        '1x': { interval: 40, step: 0.8},  
        '2x': { interval: 40, step: 1 },
        '3x': { interval: 40, step: 1.2 },
        '4x': { interval: 40, step: 1.4 }, 
        '5x': { interval: 40, step: 1.6 }, 
     };

      if (scroll !== '0x' && lyricsContainer) {
         const hitBottom = lyricsContainer.scrollTop + lyricsContainer.clientHeight >= lyricsContainer.scrollHeight;
         const { interval, step } = scrollSpeeds[scroll] || { interval: 40, step: 0.4 };

         scrollIntervalId = setInterval(() => {
            if(!isUserScrolling.current && !hitBottom){
              lyricsContainer.scrollBy({ top: step, behavior: 'smooth' });
            }          
         }, interval);
      }

      return () => {
         if (scrollIntervalId) {
            clearInterval(scrollIntervalId);
         }
      };
   }, [scroll]);

  const init = async () => {
    await AS.loadInterstitial();
    AS.showInterstitial();
  }

  useEffect(()=> {
    init();
  },[])

  const {store, updateStoreFunc} = useStore();

  const headerHeight = 60;

  const handleClickEdit = () => {
    localStorage.setItem("chordPro",formattedLyrics.current);
    // localStorage.setItem("lyrics",lyrics.join('\n'));
    const songInfo = {
      singerName: songDetailData?.details.artist.name,
      songName: songDetailData?.details.name,
      singerId: songDetailData?.details.artist.artist_id,
      chordId: songDetailData?.details.chord_id,
      songId: songDetailData?.details.id,
      songUid: songDetailData?.details.user_id,
      chordUid: songDetailData?.details.chord_uid
    }
    navigate('/dataEntry/addLyrics',{state: {songInfo: songInfo}});
  }
  const handleClickFavourite = () => {
    const f = fav === "1" ? "0" : "1";
    api.post(endpoints.song.setFav,{songId: songDetailData?.details.id,chordId: songDetailData?.details.chord_id, favorite: f})
    setFav(f);
  }
  const handleClickSetting = () => {
    setIsSettingDrawerOpen(true);
  }
  const handleClickScroll = () => {
    setScroll((prevScroll) => {
      const currentScrollValue = parseInt(prevScroll.replace('x', ''));
      const nextScrollValue = currentScrollValue < 5 ? currentScrollValue + 1 : 0;
      return `${nextScrollValue}x`;
    });
  }

  const handleTransposerChange = (level: string) => {
    let l = Number(level);
    // Gather all chords into a single string
    const allChords = Object.values(orgChordPositions).join(' ');

    // Transpose the gathered chords
    const transposedChordsString = Number(l) > 0
      ? Transposer.transpose(allChords).up(Math.abs(l)).toString()
      : Transposer.transpose(allChords).down(Math.abs(l)).toString();

    if(!transposedChordsString) return;

    // Split the transposed string back into individual chords
    const transposedChordsArray = transposedChordsString.split(' ');

    // Create a new object with the transposed chords
    const transposedChords: Record<string,string> = {};
    Object.keys(chordPositions).forEach((position, index) => {
      transposedChords[position] = transposedChordsArray[index];
    });

    setChordPositions(transposedChords);
  }

  const handleChordLevelChange = (t: {id: string,value: string}) => {
    if(t.value === '0'){
      setChordPositions(orgChordPositions);
    }else{
      handleTransposerChange(t.value);
    }
  }

  const handleFontSizeChange = (value: {id: string,value: string}) => {
    switch(value.value) {
      case '0':
        setLyricsFontsize("16px");
        setChordFontsize('12px')
        break;
      case '1':
        setLyricsFontsize("16.5px");
        setChordFontsize('12.5px')
        break;
      case '2':
        setLyricsFontsize("17px");
        setChordFontsize('13px')
        break;
      case '3':
        setLyricsFontsize("17.5px");
        setChordFontsize('13.5px')
        break;
      case '4':
        setLyricsFontsize("18px");
        setChordFontsize('14px')
        break;
    }
  }

  const handleThemeChange = () => {
    setLightThemeOn(pre => !pre)
  }

  
  useEffect(() => {
    getDetail();
  }, []);
  
  const decoded = getDecoded();
  const uid = decoded?.userId;
  const isUserOwnChord = (uid === songDetailData?.details.chord_uid) ? true : false;

  const backgroundColor = lightThemeOn? '#E9E9E9' : '#1A1A1A';
  const headerColor = lightThemeOn ? 'black' : 'white';
  const lyricsColor = lightThemeOn ?'#111111' : '#FFFFFF';
  const chordColor = lightThemeOn ? '#2E5CFF' : '#FFB800';
  // const tagColor = lightThemeOn ? '#2E5CFF' : 'black';

  return (
      <div>
          <SettingDrawer  
            isOpen={isSettingDrawerOpen} 
            onClose={() => setIsSettingDrawerOpen(false)} 
            handleFontSizeChange={handleFontSizeChange}
            handleLevelChange={handleChordLevelChange}
          />
          <Header 
             songDetailData={songDetailData}
             singer={songDetailData?.details?.artist?.name || ""} 
             songName={songDetailData?.details?.name || ""}
             favorite={fav}
             scroll={scroll}
             handleClickEdit={handleClickEdit}
             handleClickFavourite={handleClickFavourite}
             handleClickSetting={handleClickSetting}
             handleClickScroll={handleClickScroll}
             loading={loading}
             isUserOwnChord={isUserOwnChord}
             lightThemeOn={lightThemeOn}
             handleThemeChange={handleThemeChange}
             backgroundColor={backgroundColor}
             headerColor={headerColor}
           />
           {
            loading  ?
            <Skeleton width={"100%"} height={"100%"} count={18} style={{marginBottom: "10px",paddingTop: "15px"}} />
            :
            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} id="lyrics-detail" className="scrollable-y remove-scrollbar" style={{height: store.screenHeight - headerHeight, fontFamily: "sans-serif", backgroundColor: backgroundColor}}>
                <div style={{textAlign: "center",paddingTop: "40px", whiteSpace: "wrap"}}>
                      {lyrics?.map((line, lineIndex) => (
                      <div key={lineIndex} style={{height: "auto",position: "relative",lineHeight: "50px"}}>
                          {isTagLine(line) ? (
                              <TagLine
                                  line={line}
                                  lineIndex={lineIndex}
                                  chordPositions={chordPositions}
                                  lyricsColor={lyricsColor}
                                  chordColor={chordColor}
                                  lyricsFontSize={lyricsFontsize}
                                  chordFontsize={chordFontsize}
                              />
                              ) : (
                              customSplit(line).map((word, wordIndex) => (
                                  <Word
                                    key={wordIndex}
                                    id={`word_${lineIndex}-${wordIndex}`}
                                    word={word}
                                    line={line}
                                    chord={chordPositions[`word_${lineIndex}-${wordIndex}`]}
                                    lyricsFontSize={lyricsFontsize}
                                    chordFontsize={chordFontsize}
                                    lyricsColor={lyricsColor}
                                    chordColor={chordColor}
                                  />
                              ))
                          )}
                      </div>
                      ))}
                </div>
                <div style={{padding: '0 10px'}}>
             <h3 style={{color: 'gray',fontSize: "20px",fontStyle: "italic",paddingTop: "10px"}}>Other Versions</h3>
             <Swiper
                slidesPerView={'auto'}
                spaceBetween={10}
                freeMode={true}
                modules={[FreeMode]}
                className="mySwiper"
                style={{marginLeft: "5px",marginTop: "10px",marginBottom: "20px"}}
              >
                {songDetailData?.versions?.map((song, index) => (
                  song.active !== 1 &&
                  <SwiperSlide
                    style={{width: "147px"}}
                    key={index} onClick={() => {
                    setLoading(true);
                    getDetail(song.chord_id)
                  }}>
                    <img
                      src={songDetailData.details.image_url}
                      alt={`Song ${songDetailData.details.name}`}
                      style={{width: "147px",height: "147px", borderRadius: '20px' }}
                    />
                    <h2 className="custom-font" style={{color: "white",textAlign: "center",fontSize: "18px",marginBottom: "0"}}>{songDetailData.details.name}</h2>
                    <h2 className="custom-font" style={{color: "white",textAlign: "center",fontSize: "15px",margin: "0",fontWeight: "normal"}}>{song.user.name}</h2>
                  </SwiperSlide>
                ))}
             </Swiper>
           </div>
            </div>
           }
          
          {/* <Footer/> */}
      </div>
  )
}

export default SongDetail;


type HeaderProps = {
   songDetailData: SongDetailsType | undefined
   songName: string,
   singer: string,
   favorite: string,
   scroll: string,
   isUserOwnChord: boolean,
   handleClickEdit: MouseEventHandler<HTMLButtonElement>,
   handleClickFavourite: MouseEventHandler<HTMLButtonElement>,
   handleClickSetting: MouseEventHandler<HTMLButtonElement>,
   handleClickScroll: MouseEventHandler<HTMLButtonElement>,
   handleThemeChange: MouseEventHandler<HTMLButtonElement>,
   loading: boolean,
   lightThemeOn: boolean,
   backgroundColor: string,
   headerColor: string
}

const Header : React.FC<HeaderProps> = ({
  songDetailData,
  songName = "",singer = "", 
  favorite = '0', 
  scroll = '1x',
  handleClickEdit,
  handleClickFavourite,
  handleClickSetting,
  handleClickScroll,
  handleThemeChange,
  loading,
  isUserOwnChord,
  lightThemeOn,
  backgroundColor,
  headerColor
} : HeaderProps ) => {

  const {previousLocation} = useRouteTracker();

  const navigatedFromEditLyrics = previousLocation === '/dataEntry/editLyrics';

  const dynamicBackHeader = navigatedFromEditLyrics ? <BackHeader to="/library"/> : <BackHeader/>;

  const {showPlaylist} = useStore();

  return (
      <div style={{display: "flex",flexDirection: "column"}}>
        <div style={{display: 'flex',justifyContent: "space-between"}}>
            {dynamicBackHeader}
            <div style={{display: 'flex',justifyContent: "space-evenly",alignItems: 'center',flexBasis: "60%"}}>
                <ButtonBase onClick={handleThemeChange}>
                  {lightThemeOn ?  <LightMode/> : <DarkMode/> }
                </ButtonBase>
                <ButtonBase onClick={handleClickEdit}>
                  {isUserOwnChord ?  <Edit/> : <Copy/> }
                </ButtonBase>
                <ButtonBase onClick={handleClickSetting}>
                  <Setting/>
                </ButtonBase>
                <CustomButton onClick={handleClickScroll} active={false} style={{padding: '5px 15px',backgroundColor: "transparent",border: "1px solid gray",borderRadius: "15px"}}>{scroll}</CustomButton>
                <ButtonBase onClick={()=>  showPlaylist(songDetailData?.details.id, songDetailData?.details.chord_id, false)} > <PlaylistIcon/> </ButtonBase>
            </div>
        </div>
        <div style={{backgroundColor: backgroundColor, paddingTop: "10px"}}>
          <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",margin: "0 17px",height: loading ? '30px' : 'auto'}}>
            <div style={{display: "flex",flexDirection: "column"}}>
              <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: headerColor,marginBottom: "5px",fontFamily: 'Arial, sans-serif'}}>{songName}</h1>
              <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: "gray",fontFamily: 'Arial, sans-serif'}}>{singer}</h1>
            </div>
          </div>
        </div>
      </div>
  )
}

interface SettingDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  handleFontSizeChange: (param: {id: string,value: string}) => void;
  handleLevelChange: (param: {id: string,value: string}) => void;
}
const SettingDrawer: React.FC<SettingDrawerProps> = ({ isOpen, onClose, handleFontSizeChange, handleLevelChange }) => {
  const {store} = useStore();
  const text = store.text;

  const [fontSize, setFontSize] = useState([
    { id: "0", value: '0' },
    { id: "1", value: '1' },
    { id: "2", value: '2' },
    { id: "3", value: '3' },
    { id: "4", value: '4' },
  ]);

  const [selectedLevel,setSelectedLevel] = useState({ id: "7", value: '0' });
  const [selectedFontsize,setSelectedFontsize] = useState({ id: "0", value: '0' });
  
  const [level, setLevel] = useState([
    { id: "0", value: '+7' },
    { id: "1", value: '+6' },
    { id: "2", value: '+5' },
    { id: "3", value: '+4' },
    { id: "4", value: '+3' },
    { id: "5", value: '+2' },
    { id: "6", value: '+1' },
    { id: "7", value: '0' },
    { id: "8", value: '-1' },
    { id: "9", value: '-2' },
    { id: "10", value: '-3' },
    { id: "11", value: '-4' },
    { id: "12", value: '-5' },
    { id: "13", value: '-6' },
    { id: "14", value: '-7' }
  ]);

  return (
    <div>
        <CustomDrawer isOpen={isOpen} onClose={onClose}>
            <div style={{display: 'flex',justifyContent: "space-evenly"}}>
              <div style={{display: 'flex',flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
                <div data-vaul-no-drag>
                  <CustomWheelPicker uniqueId='wheel-songDetail-level' data={level} handleDataChange={(v)=> setSelectedLevel(v)} selectedId={selectedLevel.id}/>
                </div>
                <CustomButton style={{marginBottom: "50px"}} active  onClick={() => handleLevelChange(selectedLevel)}>{text.Transpose}</CustomButton>
              </div>
              <div style={{display: 'flex',flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
                <div data-vaul-no-drag>
                  <CustomWheelPicker uniqueId='wheel-songDetail-fontsize' data={fontSize} handleDataChange={(v)=> setSelectedFontsize(v)} selectedId={selectedFontsize.id}/>
                </div>
                <CustomButton style={{marginBottom: "50px"}} active onClick={() => handleFontSizeChange(selectedFontsize)}>{text['Change Font']}</CustomButton>
              </div>
            </div>
        </CustomDrawer>
    </div>
  );
};


const isTagLine = (line: string) => {
    return line.startsWith('(');
}

const getTitleInsideParentheses = (line: string) => {
  const match = line.match(/\(([^)]+)\)/);
  return match ? match[1] : '';
};

type TagLineProps = {
    line: string,
    lineIndex: number
    chordPositions: Record<string, string> ,
    lyricsColor: string,
    chordColor: string,
    lyricsFontSize: string,
    chordFontsize: string
}
const TagLine : React.FC<TagLineProps> = ({ line, lineIndex, chordPositions, lyricsColor, chordColor, lyricsFontSize, chordFontsize }) => (
    <div className="tag-line">
      {
        customSplit(line).map((word, wordIndex) =>
          word.startsWith('(') ? (
            <TitleComponent key={wordIndex} lineIndex={lineIndex} title={getTitleInsideParentheses(line)} />
          ) : (
            <Word
              key={`word_${lineIndex}-${wordIndex}`}
              id={`word_${lineIndex}-${wordIndex}`}
              word={word}
              line={line}
              chord={chordPositions[`word_${lineIndex}-${wordIndex}`]}
              lyricsFontSize={lyricsFontSize}
              chordFontsize={chordFontsize}
              tagLine={true}
              lyricsColor={lyricsColor}
              chordColor={chordColor}
            />
          )
        )
      }
    </div>
);

interface TitleComponentProps {
  title: string;
  lineIndex: number;
}
  

const TitleComponent: React.FC<TitleComponentProps> = ({ title, lineIndex }) => {
    return (
        <div>
          <div  style={{cursor: 'pointer',display: 'flex',justifyContent: "center",alignItems: "center",color: '#3373EF', position: 'absolute', left: '0px', bottom: "5px", padding: '0 8px'}}>
              <Tag style={{marginRight: "5px"}}/>
              <div style={{textAlign: "start",userSelect: 'none'}}>
                <div>{title}</div>
                {/* <div style={{marginTop: "5px", width: "300px",height: "1px",backgroundColor: "#414141"}}></div> */}
              </div>
          </div>
        </div>
    )
};


type WordProps = {
  word: string;
  line: string;
  id: string;
  chord?: string;
  lyricsFontSize?: string;
  chordFontsize?: string;
  tagLine?: boolean;
  lyricsColor: string;
  chordColor: string
}

const Word: React.FC<WordProps> = ({ word, line, id, chord, lyricsFontSize = '16px', chordFontsize = '12px', tagLine = false , lyricsColor, chordColor }) => {
    const style = {
      display: 'inline-block',
      margin: '0px',
      position: 'relative',
      color: lyricsColor,
      borderLeft: 'none', 
      fontSize: lyricsFontSize,
      fontFamily: "Arial, sans-serif",
    } as React.CSSProperties;

    const emptySpace = tagLine ? '' : '5px'
  
    return (
      <span  style={style}>
        <div  id={`box-${id}`} className="chord-box" style={{ position: 'absolute', top: isTagLine(line)? '5px' : '-15px', left: '50%', transform: 'translateX(-50%)', width: '10px', height: '10px'}}>
          {chord && (
            <Chord key={`${id}-${chord}`} chord={chord} containerId={id} chordFontSize={chordFontsize} chordColor={chordColor}/>
          )}
        </div>
        {word === " " ? <div css={css`font-family: 'Arial, sans-serif' !important;`} style={{ width: emptySpace, color: "transparent"}}>င</div> : word}
      </span>
    );
  };

  type ChordProps = {
    chord: string;
    containerId: string,
    chordFontSize: string,
    chordColor: string
  }

  const Chord: React.FC<ChordProps> = ({ chord, containerId, chordFontSize, chordColor }) => {
  
    const style = {
      fontSize: chordFontSize,
      touchAction: 'none',
      color: chordColor,
      fontWeight: "bold",
      cursor: 'pointer',
      zIndex: 1000,
      position: "fixed",
      top: -8,
      right: 0,
      fontFamily: 'sans-serif'
    } as React.CSSProperties;
  
    return (
      <div  style={{position: 'relative'}}>
        <div style={style} className="chord" >
          {chord}
        </div>
      </div>
  );
};


