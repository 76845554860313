/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { SplashScreen } from "@capacitor/splash-screen";
import { SkeletonTheme } from 'react-loading-skeleton';

import './App.css';
import { StoreProvider, useStore} from './store';
import Home from './components/HomeComponents/Home';
import Profile from './components/ProfileComponents/Profile';
import DataEntry from './components/DataEntryComponents/DataEntry';
import Library from './components/LibraryComponents/Library';
import config from './config';
import LyricsForm from './components/DataEntryComponents/LyricsForm';
import ChordsForm from './components/DataEntryComponents/ChordsForm';
import Test from './Test';
import EditProfile from './components/ProfileComponents/EditProfile';
import NewSongs from './components/HomeComponents/NewSongs';
import Artists from './components/HomeComponents/Artists';
import SongDetail from './components/CommonComponents/SongDetail';
import Login from './components/LoginComponents/Login';
import PublicSearch from './components/HomeComponents/PublicSearch';
import SongListsBySpecficId from './components/CommonComponents/SongListsBySpecificId';
import { setFontFamily } from "./utils";
import useBackButtonListener from "./components/CommonComponents/useBackButtonListener";
import { RouteTrackerProvider } from "./components/CommonComponents/RouteTracker";
import UserProfile from "./components/CommonComponents/UserProfile";
import SongVersionsList from "./components/CommonComponents/SongVersionsList";
import CollectionList from "./components/HomeComponents/CollectionList";
import Playlist from "./components/HomeComponents/Playlist";
import axios from "axios";
import { endpoints } from "./api/endpoints";
import { CapacitorUpdater } from "@capgo/capacitor-updater";

function App() {
  const {store,updateStoreFunc} = useStore();
  useBackButtonListener();

  const init = async () => {
    try {
      await CapacitorUpdater.notifyAppReady();
      const {data: result} : any = await axios.get(endpoints.codePush.checkUpdate);
      console.log('result',result)
      if (result.code !== '200') {
        SplashScreen.hide();
        return;
      }
      const latest : any = result.data;
      const currentVersion = localStorage.getItem('appVersion') || '1.0.0';
      console.log("latest", latest);
      if(!latest.url || currentVersion === latest.version){
        SplashScreen.hide();
        return;
      }

      console.log(`Current version: ${currentVersion}, New version: ${latest.version}`);
      const version = await CapacitorUpdater.download({
        url: latest.url,
        version: latest.version,
      });
      console.log("version",version);
      await CapacitorUpdater.set(version);
      localStorage.setItem('appVersion', latest.version);

    } catch (error) {
      console.log("code push error: ",error)
      SplashScreen.hide();
    }
  }

  useEffect(()=> {
    console.log("render-----")
    init();
  },[])
  
  return (
    <div style={{maxWidth: config.screenWidth,marginLeft: "auto",marginRight:"auto"}}>
      <SkeletonTheme baseColor="#313131" highlightColor="#525252">
          <Global styles={setFontFamily(store.lang)}/>
          <BrowserRouter>
            <RouteTrackerProvider>
              <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<PrivateRoute />}>
                    <Route index element={<Navigate to="home" replace />} />
                    <Route path="home" element={<Home />} />
                    <Route path="home/search" element={<PublicSearch />} />
                    <Route path="home/collectionList" element={<CollectionList />}/>
                    <Route path="playlist" element={<Playlist />}/>
                    <Route path="home/songListsBySpecificId" element={<SongListsBySpecficId />}/>
                    <Route path="home/newSongs" element={<NewSongs />}/>
                    <Route path="home/artists" element={<Artists />}/>
                    <Route path="profile" element={<Profile />}/>
                    <Route path="userProfile" element={<UserProfile />}/>
                    <Route path="profile/editProfile" element={<EditProfile />}/>
                    <Route path="dataEntry" element={<DataEntry />}/>
                    <Route path="dataEntry/addLyrics" element={<LyricsForm />}/>
                    <Route path="dataEntry/editLyrics" element={<ChordsForm />}/>
                    <Route path="songDetail" element={<SongDetail />}/>
                    <Route path="songVersions" element={<SongVersionsList />}/>
                    <Route path="library" element={<Library />}/>
                    <Route path="test" element={<Test />}/>
                  </Route>
                  <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
            </RouteTrackerProvider>
          </BrowserRouter >
      </SkeletonTheme>
    </div>
  );
}

const PrivateRoute: React.FC = () => {
  const token = localStorage.getItem('token');
  const {store, updateStoreFunc} = useStore();

  const handlePopState = () => {
    const pathName = window.location.pathname;
    let currentPage = "/" + pathName.split('/')[1];
    if(pathName === '/login'){
      currentPage = '/home'
    }
    updateStoreFunc('currentPage', currentPage);
  }

  useEffect(()=> {
    const pathName = window.location.pathname;
    let currentPage = "/" + pathName.split('/')[1];
    updateStoreFunc('currentPage', currentPage);
  },[window.location.pathname])

  useEffect(()=> {
    const pathName = window.location.pathname;
    let currentPage = "/" + pathName.split('/')[1];
    updateStoreFunc('currentPage', currentPage);
    window.addEventListener("popstate", handlePopState); // to update page when user click back button
  },[])

  return token ? <Outlet /> : <Navigate to="/login" replace />;

};


export default App;
