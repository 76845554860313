import { css } from "@emotion/react";
import { jwtDecode } from "jwt-decode";
import { DecodedType } from "./types";
import { Modal } from "antd";


export const customSplit = (str: string) : string[] => {
  // Split by Myanmar characters and keep the characters
  let result = str.split(/(?<!^)(?=[က-အ])/);

  //to seperate english character and spaces
  result = result.flatMap(part => part.split(/(\s+)/).filter(word => word.length > 0));
  const separateChordAnnotations = (str: string) => {
    const chordPattern = /\[(.*?)\]/g;
    const result = [];
    let lastIndex = 0;

    let match;
    while ((match = chordPattern.exec(str)) !== null) {
      if (match.index > lastIndex) {
        result.push(str.slice(lastIndex, match.index));
      }
      result.push(match[0]);
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < str.length) {
      result.push(str.slice(lastIndex));
    }

    return result;
  };

  // Further split based on spaces and then handle chords and parenthesis
  result = result.flatMap(part => 
    part.split(/(\s+)/).flatMap(subPart => {
      // Check if it's a space; if yes, split it further
      if (/^\s+$/.test(subPart)) {
        return subPart.split('');
      }
      return separateChordAnnotations(subPart);
    })

  );
  return result;
  // return result.flatMap(separateChordAnnotations);
};

export const removeChordFromLyrics = (lyrics: string) => {
  let l : string[] = lyrics.split('\n');
  const positions: Record<string, string> = {};
  let removedChordLyrics: string[] = [];

  l.forEach((line, lineIndex) => {
    const words = customSplit(line);
    let newLine = line;

    words.forEach((word, wordIndex) => {
      const chordMatches = word.match(/\[([^\]]+)\]/g);
      if (chordMatches) {
        chordMatches.forEach(chordMatch => {
          const chord = chordMatch.slice(1, -1); // Remove the brackets
          const id = `word_${lineIndex}-${Number(wordIndex)}`;

          positions[id] = chord; 
          newLine = newLine.replace(chordMatch, '');

        });
        words.splice(wordIndex, 1);
      }
    });
    removedChordLyrics[lineIndex] = newLine;
  });
  return {positions,removedChordLyrics}
}

export const generateFormattedLyrics = (lyrics: string[], chordPositions: Record<string, string>) => {
  const formattedLyrics =  lyrics.map((line, lineIndex) => {
    const words = customSplit(line);
    const formattedWords = words.map((word, wordIndex) => {
      const positionKey = `word_${lineIndex}-${wordIndex}`;
      if (chordPositions[positionKey]) {
        return `[${chordPositions[positionKey]}]${word}`;
      }
      return word;
    });
    return formattedWords.join('');
  });
  return formattedLyrics.join('\n')
};

export const getDecoded = () => {
  const token : string = localStorage.getItem("token") as string;
  const decoded : DecodedType = jwtDecode(token);
  return decoded
}

export const setFontFamily = (lang : string) => {
  const fontFamily = `${lang === 'my' ? "'Padauk', sans-serif !important" : 'Arial, sans-serif'}`;

  return css`
      html{
        font-family: ${fontFamily};
      }
      textarea{
        font-family: ${fontFamily};
      }
      div{
        font-family: ${fontFamily};
      }
      button{
        font-family: ${fontFamily};
      }
      h1{
        font-family:  ${fontFamily};
      }

      h2{
        font-family:  ${fontFamily};
      }

      h3{
        font-family:  ${fontFamily};
      }

      h4{
        font-family:  ${fontFamily};
      }

      h5{
        font-family:  ${fontFamily};
      }

      h6{
        font-family:  ${fontFamily};
      }
    `
}

export const generateTikTokCodeVerifier = (length: number = 128): string => {
  const array = new Uint32Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');
};

export const saveDraft = (chordPro: string, userId: number, singerId: number, songName: string, chordId: string | undefined) => {
  const key = `${userId}_${singerId}_${songName}_${chordId}_draft`;
  const draft = {
    chordPro: chordPro,
    userId: userId,
    singerId: singerId,
    songName: songName,
    lastModified: new Date().getTime()
  };
  localStorage.setItem(key, JSON.stringify(draft));
  localStorage.setItem('chordPro', draft?.chordPro);
}

export const loadDraft = (title: string, content: string, okText: string, cancelText: string, userId: number, songName: string, singerId: number, chordId: string | undefined, ask: boolean = false, navigatedFromDataEntry: boolean = false) : Promise<string> => {
  const key = `${userId}_${singerId}_${songName}_${chordId}_draft`;
  const orgChordPro = navigatedFromDataEntry ? "" : (localStorage.getItem('chordPro') || "");

  const draft = localStorage.getItem(key);
  if (draft) {
    const parsedDraft = JSON.parse(draft);
    const now = new Date().getTime();
    const isExpired = (now - parsedDraft.lastModified) > 3 * 60 * 60 * 1000; // 3 hour
    
    if (isExpired) {
      localStorage.removeItem(key);
      return Promise.resolve(orgChordPro);
    } else {
      if(ask){
        return new Promise((resolve, reject) => {
          Modal.confirm({
            title: title,
            content: content,
            okText: okText,
            cancelText: cancelText,
            centered: true,
            onOk() {
              resolve(parsedDraft?.chordPro);
            },
            onCancel() {
              resolve(orgChordPro);
            },
          });
        });
      }else{
        return Promise.resolve(orgChordPro);
      }
    }
  }
  return Promise.resolve(orgChordPro);
}

export const removeDraft = (userId: number, singerId: number, songName: string, chordId: string = 'none') => {
  const key = `${userId}_${singerId}_${songName}_${chordId}_draft`;
  localStorage.removeItem(key);
}