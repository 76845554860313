/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import { css } from "@emotion/react";
import WheelPicker, { PickerData } from 'react-simple-wheel-picker';

type CustomWheelPickerProps = {
  data: { id: string, value: string }[];
  handleDataChange: (value: { id: string, value: string }) => void;
  uniqueId: string,
  height?: number, 
  selectedId?: string
};

const CustomWheelPicker : React.FC<CustomWheelPickerProps> = ({data, handleDataChange, uniqueId, height = 220, selectedId}) => {
  
	const handleChange = (target: any) => {
		handleDataChange(target);
    // console.log("uniqueId",uniqueId)
    const listItems : NodeListOf<HTMLLIElement>= document.querySelectorAll(`.${uniqueId} li`);

    let selectedIndex = Number(target.id);

    listItems.forEach((li: HTMLLIElement, index) => {
      const distance = Math.abs(selectedIndex - index);
      const baseSize = 25; 
      const sizeDecrease = 6; 
      const baseOpacity = 1; 
      const opacityDecrease = 0.4;

      const newSize = baseSize - distance * sizeDecrease;
      const newOpacity = baseOpacity - distance * opacityDecrease;

      const pElement = li.querySelector('p');
      if (pElement) {
        pElement.style.setProperty('font-size', `${newSize}px`, 'important');
        pElement.style.setProperty('opacity', `${newOpacity}`, 'important');
        pElement.style.transition = 'font-size 0.3s ease, opacity 0.3s ease';
        if(selectedIndex == index)  {
          li.style.setProperty('border-width', '5px 0 5px 0', 'important');
          li.style.setProperty('border-style', 'solid', 'important');
          li.style.setProperty('border-color', '#3B3B3B', 'important');
          // li.style.setProperty('border-radius', '20px', 'important');
        }else{
          li.style.setProperty('border-width', '0px 0 0px 0', 'important')
        }
      }
    });
  
	};

	return (
      <div className={`remove-scrollbar ${uniqueId}`} style={{padding: "0px 0 20px 0",marginTop: "30px"}}
        css={css`
          .ieqYiF {
            color: transparent !important;
          }
          li[aria-selected="false"] .diFZYU {
            font-size: 20px !important; 
            color: gray !important; 
          }
        `}
      >
          <WheelPicker
            data={data as PickerData[]}
            onChange={handleChange}
            height={height}
            width={100}
            itemHeight={50}
            selectedID={selectedId || data[Math.round(data.length / 2)]?.id}
            color="grey"
            activeColor="white"
            backgroundColor="transparent"
            fontSize={25}
            shadowColor="none"
            focusColor="none"
          />
      </div>    
	);
};

export default CustomWheelPicker;