/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { InputProps } from "antd";
import { Img, ImgProps } from "react-image";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

type CustomImageProps = ImgProps & {
    borderRadius: string
    style?: React.CSSProperties
}

export default function CustomImage({ children, style, width, height, src, alt, borderRadius, ...rest }: CustomImageProps) {
  return (
    <Img width={width} height={height} style={{borderRadius: borderRadius,objectFit: "cover",...style}}
        src={src}
        alt={alt}
        loader={<Skeleton width={width} height={height} borderRadius={borderRadius}/>}
        unloader={<Skeleton width={width} height={height} borderRadius={borderRadius}/>}
        {...rest}
    />
  )
}
