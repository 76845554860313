import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { Transposer } from 'chord-transposer';
import { message, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomButton from '../CommonComponents/CustomButton';
import BackHeader from '../CommonComponents/BackHeader';
import DndComponent from './DndComponent';
import {generateFormattedLyrics, getDecoded, removeChordFromLyrics, removeDraft } from '../../utils';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import {ReactComponent as TrashIcon} from '../../assets/icons/trashIcon.svg'
import { useStore } from '../../store';
import Loader from '../CommonComponents/Loader';

type SongType = {
  albumId: number;
  composerId: number;
  lyrics: string;
  singerId: number;
  singerName: string;
  songName: string;
  songNameMyanmar: string;
  songNameEnglish: string;
  songPhoto: string;
  tags: string | undefined;
  chordId?: string,
  songId?: string,
  chordUid: number
};


export default function ChordsForm() {

  const location = useLocation();
  const songInfo : SongType = location?.state?.songInfo;
  const l = location?.state?.songInfo?.lyrics?.split("\n") || []; 
  const [publishLoading, setPublishLoading] = useState(false);

  const [lyrics, setLyrics] = useState<string[]>(l);
  const [chordPositions, setChordPositions] = useState<Record<string, string>>({});

  const {store} = useStore();
  const text = store.text;

  const isUserOwnChord = useRef(false);

  const navigate = useNavigate();

  const handlePublishClick = async () => {
      const formatedLyrics = generateFormattedLyrics(lyrics, chordPositions);

      setPublishLoading(true);

      let params;
      let dynamicApi;
      let result: any;

      if(isUserOwnChord.current){
        params = {
          songId: songInfo.songId,
          chordId: songInfo.chordId,
          chordPro: formatedLyrics,
          name: songInfo.songName,
          artistId: songInfo.singerId
        };
        dynamicApi = endpoints.song.songEdit;
        result  = await api.put(dynamicApi, params);
        if(result.code === "200") {
          message.success('Successfully edited song');
          if(typeof result.data === 'object') {
            localStorage.removeItem('chordPro');
            removeDraft(decoded.userId, songInfo.singerId, songInfo.songName, songInfo.chordId);
            
            navigate("/songDetail",{state: result.data});
          }
        }else{
          message.error(result.message);
        }
      }else{
        params = {
          name: songInfo.songName,
          nameMm: songInfo.songNameMyanmar,
          nameEn: songInfo.songNameEnglish,
          lyrics: formatedLyrics,
          artistId: songInfo.singerId,
          albumId: songInfo.albumId,
          tags: songInfo.tags,
          composerId: songInfo.composerId,
          status: '1',
          musicLink: 'example.com',
          image: songInfo.songPhoto,
          key: `ABC`,
          chordPro: formatedLyrics,
        };
        dynamicApi = endpoints.song.addSong;
        result = await api.post(dynamicApi, params);
        if(result.code === "200") {
          message.success('Successfully added new song');
          localStorage.removeItem('chordPro');
          removeDraft(decoded.userId, songInfo.singerId, songInfo.songName, songInfo.chordId);

          if(typeof result.data === 'object') {
            navigate("/songDetail",{state: result.data});
          }else{
            navigate('/dataEntry',{replace: true})
          }
        }else{
          message.error(result.message);
        }
      }
      setPublishLoading(false);
  };

  const handleTransposerChange = (level: string) => {
    let l = Number(level);
    // Gather all chords into a single string
    const allChords = Object.values(chordPositions).join(' ');

    // Transpose the gathered chords
    const transposedChordsString = Number(l) > 0
      ? Transposer.transpose(allChords).up(Math.abs(l)).toString()
      : Transposer.transpose(allChords).down(Math.abs(l)).toString();

    if(!transposedChordsString) return;

    // Split the transposed string back into individual chords
    const transposedChordsArray = transposedChordsString.split(' ');

    // Create a new object with the transposed chords
    const transposedChords: Record<string,string> = {};
    Object.keys(chordPositions).forEach((position, index) => {
      transposedChords[position] = transposedChordsArray[index];
    });

    setChordPositions(transposedChords);
  }

  useEffect(()=> {
    const chordPro = localStorage.getItem('chordPro');
    if(!chordPro ) return;
    const {positions,removedChordLyrics} = removeChordFromLyrics(chordPro);
    setLyrics(removedChordLyrics);
    setChordPositions(positions);
  },[])
  
  useEffect(()=> {
    const formatedLyrics = generateFormattedLyrics(lyrics, chordPositions);
    localStorage.setItem("chordPro",formatedLyrics);
  },[lyrics,chordPositions])

  const handleClearChords = () => {
    Modal.confirm({
      title: text['Are you sure?'],
      content: text['Do you want to clear all chords'],
      okText: text['Yes'],
      cancelText: text['No'],
      onOk() {
        setChordPositions({});
      },
    });
    
  }

  const decoded = getDecoded();
  const uid = decoded?.userId;
  isUserOwnChord.current = (uid === songInfo?.chordUid) ? true : false;

  return (
    <div style={{position: "relative"}}>
      <BackHeader/>
      <EditHeader publishLoading={publishLoading} singer={songInfo?.singerName} songName={songInfo?.songName} handlePublishClick={handlePublishClick} handleClearChords={()=> handleClearChords()} isUserOwnChord={isUserOwnChord.current}/>
      <DndComponent lyrics={lyrics} chordPositions={chordPositions} handleChordPositionsChange={setChordPositions} handleLyricsChange={setLyrics} handleTransposerChange={handleTransposerChange}/>
      {/* <Footer/> */}
    </div>
  )
}

type EditHeaderProps =  {
    songName: string,
    singer: string,
    handlePublishClick: Function,
    handleClearChords: MouseEventHandler<SVGSVGElement>
    isUserOwnChord: boolean,
    publishLoading: boolean
}

const EditHeader = ({songName = "",singer = "", handlePublishClick, handleClearChords, isUserOwnChord, publishLoading} : EditHeaderProps ) => {
    const {store} = useStore();
    const text = store.text;

    const publishText = publishLoading ? <Loader btn/> : text['Publish'];
    const editText = publishLoading ? <Loader btn/> : text['Edit'];
    
    return (
        <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",margin: "0 17px", height: "80px"}}>
          <div style={{display: "flex",flexDirection: "column"}}>
            <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: "white",marginBottom: "5px",fontFamily: 'Arial, sans-serif'}}>{songName}</h1>
            <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: "gray",fontFamily: 'Arial, sans-serif'}}>{singer}</h1>
          </div>
          <div style={{display: "flex",alignItems: "center",justifyContent: "center"}}>
            <TrashIcon onClick={handleClearChords} style={{marginRight: "20px",cursor: "pointer"}}/>
            <CustomButton disabled={publishLoading} onClick={()=> handlePublishClick()} style={{padding: '10px 20px', width: "88px"}}  active>{isUserOwnChord ? editText : publishText}</CustomButton>
          </div>
        </div>
    )
  }
