import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Footer from '../CommonComponents/Footer'
import { useStore } from '../../store';
import NewSingerInputForm from './NewSingerInputForm';
import NewSongInputForm from './NewSongInputForm';
import Header from './Header';
import NewAlbumInputForm from './NewAlbumInputForm';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import { message } from 'antd';

export default function DataEntry() {
  const [activeHeader,setActiveHeader] = useState('new song');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {store, updateStoreFunc} = useStore();

  const handleNewSongFinish = (value: {
    albumId: number,
    composerId: number,
    singerId: number,
    singerName: string,
    songName: string,
    songNameMyanmar: string,
    songNameEnglish: string,
    songPhoto: string,
    tags: string[]
  }) => {

    console.log("value",value);
  
    let songInfo = {
      albumId: value.albumId,
      composerId: value.composerId,
      singerId: value.singerId,
      singerName: value.singerName,
      songName: value.songName,
      songNameMyanmar: value.songNameMyanmar,
      songNameEnglish: value.songNameEnglish,
      songPhoto: value.songPhoto,
      tags: value?.tags?.join(',') || "",
      chordUid: null
    };
    localStorage.setItem('songInfo', JSON.stringify(songInfo));
    navigate('/dataEntry/addLyrics',{state: {songInfo}});
  }

  const handleNewSingerFinish = async (value: {
    gender: string,
    remark: string,
    singerName: string,
    singerNameMyanmar: string,
    singerNameEnglish: string,
    singerPhoto: string
  }) => {
    const params = {
      name: value.singerName,
      nameMm: value.singerNameMyanmar,
      nameEn: value.singerNameEnglish,
      type: value.gender,
      image: value.singerPhoto,
      remark: value.remark
    };
    console.log('value: ',value);
    if(!value.singerPhoto) return message.info("Please upload image!");
    setLoading(true);
    const result: any = await api.post(endpoints.artist.addArtist,params);
    setLoading(false);
    if(result.code === '200') {
      message.success("Successfully add new artist")
    }else{
      message.error(result.message);
    }
  }

  const handleNewAlbumFinish = async (value : {
    albumName: string;
    albumPhoto: string;
    description: string
  }) => {
    const params = {
      name: value.albumName,
      image: value.albumPhoto,
      description: value.description
    };
    console.log('value: ',value)
    // if(!value.albumPhoto) return message.info("Please upload image!")
    setLoading(true);
    const result: any = await api.post(endpoints.alblum.addAlbum,params);
    setLoading(false);
    if(result.code === '200') {
      message.success("Successfully add new artist")
    }else{
      message.error(result.message);
    }
  }

  const handleChangeHeader = (v: string) => setActiveHeader(v);

  return (
    <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight}}>
        <div style={{padding: "15px"}}>
          <Header handleChangeHeader={handleChangeHeader} activeHeader= {activeHeader}  />
          {activeHeader === 'new song' ?
            <NewSongInputForm handleNewSongFinish={handleNewSongFinish}/>
            :
           activeHeader === 'new singer' ?
            <NewSingerInputForm handleNewSingerFinish={handleNewSingerFinish} loading={loading}/>
            :
            <NewAlbumInputForm handleNewAlbumFinish={handleNewAlbumFinish} loading={loading}/>
          }
        </div>
        <Footer/>
    </div>
  )
}




